import React, { useEffect, useState } from "react";
import './App.css';
import Header from "./components/Header";
import Login from './pages/Login/Login';
import { auth, db } from './config/fire'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import Home from "./pages/Home";
import Singers from "./pages/Singers";
import Songs from "./pages/Songs";
import EventPage from "./pages/EventPage";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { Spinner } from "react-bootstrap";
import Admins from "./pages/Admins";
import { signOut } from "firebase/auth";
function App() {
  const [user] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState(false)
  const [allAdmins, setAllAdmins] = useState(null)
  useEffect(() => {
    onSnapshot(doc(db, 'admin', 'verifiedAdmins'), (res) => {
      setAllAdmins(res.data()?.uids) 
    })
  }, [auth]) 
  if (!allAdmins ) {
    return (
      <div style={{ width: '100vw', background:'#000', height: '100vh', display: 'flex', alignItems:'center', justifyContent: 'center'  }}>
        <Spinner style={{color:'#007bff'}}/>
      </div>
    )
  } 
  if(!user){
    return(
        <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'centers' }}>
          <Login />
        </div>
    )
  }
  if (!allAdmins.includes(user?.uid)) {
    return (
      <div style={{ width: '100vw', background:'#000', height: '100vh', display: 'flex', alignItems:'center', justifyContent: 'center'  }}>
        <h1 onClick={()=>{signOut(auth)}} style={{color:'#fff' , textAlign:'center'}}>You do not have access yet.</h1>
      </div>
    )
  }
  console.log(user);
  return (
    <>  
        <Router>
          <div className="app">
            <div className="app__header">
              <Header />
            </div>
            <div className="app__body">
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/singers">
                  <Singers />
                </Route>
                <Route exact path="/songs">
                  <Songs />
                </Route>
                <Route exact path="/event">
                  <EventPage />
                </Route>
                <Route exact path="/admins">
                  <Admins />
                </Route>
              </Switch>
            </div>
          </div>
        </Router> 
    </>
  );
}

export default App;
