import axios from "axios";
import React, { useState } from "react";

const SelectWithTextInput = ({ setVal }) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);

  const [songList, setSongList] = useState(null);
  const fetchSongData = async (q) => {
    try {
      const response = await axios.get(
        "https://deezerdevs-deezer.p.rapidapi.com/search",
        {
          params: { q: q },
          headers: {
            "X-RapidAPI-Key":
              "f9b8944022msh51bb7b407d10c36p1eb8dajsn769100c2cbc9",
            "X-RapidAPI-Host": "deezerdevs-deezer.p.rapidapi.com",
          },
        }
      );
      setSongList(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setVal(value);

    // const filtered = options.filter((songList) =>
    //   songList.name.toLowerCase().includes(value.toLowerCase())
    // );
    // setFilteredOptions(filtered);
    setSelectedOption(null); // Clear selected option when input value changes
  };

  const handleOptionSelect = (option) => {
    setInputValue(option);
    setVal(option);
    setSelectedOption(option);
    setFilteredOptions([]);
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => {
            setIsVisible(true);
          }}
          placeholder="Select Song"
          style={{
            borderRadius: "5px",
            fontSize: "0.9em",
            padding: "8px 15px",
            color: "#fff",
            background: "#151515",
            width: "-webkit-fill-available",
            marginBottom: "10px",
          }}
        />
        <button
          onClick={() => {
            fetchSongData(inputValue);
          }}
          style={{
            borderRadius: "5px",
            fontSize: "0.9em",
            padding: "8px 15px",
            color: "#fff",
            background: "#151515",
            width: "100px",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
        >
          Search
        </button>
      </div>
      <ul
        style={{
          position: "absolute",
          background: "#6d6d6d",
          padding: "0px 30px",
          maxHeight: isVisible ? "400px" : "0px",
          overflow: "scroll",
        }}
      >
        {songList &&
          songList.map((option, index) => (
            <li
              style={{ color: "#fff", cursor: "pointer" }}
              key={index}
              onClick={() => {
                handleOptionSelect(`${option.title} by ${option.artist.name}`);
                setIsVisible(false);
              }}
            >
              {option.title} by {option.artist.name}
            </li>
          ))}
      </ul>
    </div>
  );
};

const CustoMSelectSong = ({ setVal }) => {
  return <div>{<SelectWithTextInput setVal={setVal} />}</div>;
};

export default CustoMSelectSong;
