// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
    getFirestore
} from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBb9v9zF8IJ3UhMJ4ndefGGGC27twGYlbA",
    authDomain: "postr-832e4.firebaseapp.com",
    databaseURL: "https://postr-832e4-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "postr-832e4",
    storageBucket: "postr-832e4.appspot.com",
    messagingSenderId: "142072316228",
    appId: "1:142072316228:web:b1bb8f4db4ec2c1dfd5711",
    measurementId: "G-XTP48BC8TD"

    // apiKey: "AIzaSyDiqmoUddQDLvoihA2QQl5JqNPnhH9hlco",
    // authDomain: "ikf-web-app.firebaseapp.com",
    // databaseURL: "https://ikf-web-app-default-rtdb.firebaseio.com",
    // projectId: "ikf-web-app",
    // storageBucket: "ikf-web-app.appspot.com",
    // messagingSenderId: "871934867884",
    // appId: "1:871934867884:web:903157685f38d5de02a209"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
export { auth };
const db = getFirestore(firebaseApp);
export { db }
const storage = getStorage(firebaseApp)
export { storage }
const database = getDatabase(firebaseApp);
export { database }

export { firebaseApp };