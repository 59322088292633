import React, { useState, useEffect, useRef } from "react";
import { IoAddOutline } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { auth, db } from "../config/fire";
import { uuidv4 } from "@firebase/util";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function Songs() {
  const nameRef = useRef(null);
  const addressRef = useRef(null);
  const [eventDate, setEventDate] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [allSongs, setAllSongs] = useState(null);

  useEffect(() => {
    onSnapshot(collection(db, "songs"), (snap) => {
      let temp = [];
      snap.docs.forEach((doc) => {
        temp.push(doc.data());
      });
      setAllSongs(temp);
    });
  }, []);

  function addSong(obj) {
    let songId = uuidv4();
    setDoc(doc(db, "songs", songId), { ...obj, songId });
  }
  function deleteSong(songId) {
    deleteDoc(doc(db, "songs", songId));
  }
  function editSong(songId) {
    const x = window.prompt("Write new name");
    if (x && x !== "") {
      setDoc(
        doc(db, "songs", songId),
        { name: x, songId: songId },
        { merge: true }
      );
    } else {
      alert("please provide a valid name");
    }
  }
  return (
    <div className="page_container">
      <buttn
        onClick={() => {
          handleShow();
        }}
        style={{
          borderRadius: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "1em",
          padding: "8px 15px",
          color: "#000",
          background: "#00b7ff",
          width: "55px",
          height: "55px",
          position: "fixed",
          right: "20px",
          bottom: "20px",
        }}
        className="btn"
      >
        <IoAddOutline style={{ fontSize: "2em" }} />
      </buttn>
      <Modal className="addLinkModal" show={show} onHide={handleClose}>
        <Modal.Body>
          <h2>New song</h2>
          <p style={{ margin: "0", color: "#6d6d6d" }}>Name of the song</p>
          <input
            ref={nameRef}
            type={"text"}
            style={{
              borderRadius: "5px",
              fontSize: "0.9em",
              padding: "8px 15px",
              color: "#fff",
              background: "#151515",
              width: "-webkit-fill-available",
              marginBottom: "10px",
            }}
          />
          <div style={{ display: "flex", marginTop: "20px" }}>
            <buttn
              onClick={handleClose}
              style={{
                borderRadius: "50vh",
                display: "flex",
                justifyContent: "center",
                fontSize: "0.9em",
                padding: "8px 15px",
                color: "#00b7ff",
                border: "1px solid #00b7ff",
                margin: "10px 5px",
                width: "-webkit-fill-available",
                marginBottom: "0",
              }}
              className="btn"
            >
              Cancel
            </buttn>
            <buttn
              onClick={() => {
                let similar = allSongs
                  .filter((Song) =>
                    Song.name
                      .toLowerCase()
                      .includes(nameRef.current.value.toLowerCase())
                  )
                  .map((Song) => Song.name);
                if (similar?.length > 0) {
                  const q = window.confirm(
                    `There is similar name already in the list\n${similar.join(
                      " // "
                    )}\nDo you want to proceed?`
                  );
                  if (q) {
                    addSong({
                      name: nameRef.current.value,
                    });
                  }
                } else {
                  addSong({
                    name: nameRef.current.value,
                  });
                }
                handleClose();
              }}
              style={{
                borderRadius: "50vh",
                display: "flex",
                justifyContent: "center",
                fontSize: "1em",
                padding: "8px 15px",
                color: "#000",
                background: "#00b7ff",
                margin: "10px 5px",
                width: "-webkit-fill-available",
                marginBottom: "0",
              }}
              className="btn"
            >
              <IoAddOutline style={{ fontSize: "1.5em", marginRight: "7px" }} />
              Add
            </buttn>
          </div>
        </Modal.Body>
      </Modal>
      <h6 style={{ marginBottom: "15px" }}>All songs</h6>
      <div className="card_container">
        {allSongs &&
          allSongs
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((data, i) => {
              return (
                <div
                  style={{
                    margin: "15px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    background: "#00b7ff30",
                    padding: "10px 15px",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h1 style={{ margin: "0", marginRight: "20px" }}>
                      {i + 1}
                    </h1>
                    <div>
                      <p
                        style={{
                          fontSize: "1em",
                          fontWeight: "500",
                          color: "#fff",
                          margin: "0",
                          marginBottom: "0",
                        }}
                      >
                        {data.name}
                      </p>
                    </div>
                    <div style={{ flex: "1" }} />
                    <p
                      onClick={() => {
                        deleteSong(data.songId);
                      }}
                      style={{
                        fontSize: "1em",
                        fontWeight: "500",
                        color: "red",
                        margin: "0",
                        marginBottom: "0",
                        marginRight: "20px",
                      }}
                    >
                      Delete
                    </p>
                    <p
                      onClick={() => {
                        editSong(data.songId);
                      }}
                      style={{
                        fontSize: "1em",
                        fontWeight: "500",
                        color: "green",
                        margin: "0",
                        marginBottom: "0",
                      }}
                    >
                      Edit
                    </p>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
}
