import React, { useState, useEffect, useRef } from 'react';
import { IoAddOutline } from 'react-icons/io5'
import Modal from 'react-bootstrap/Modal';
import { collection, deleteDoc, doc, onSnapshot, setDoc, Timestamp } from 'firebase/firestore';
import { auth, db } from '../config/fire';
import { uuidv4 } from '@firebase/util';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
export default function Singers() {
    const nameRef = useRef(null)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [name, setName] = useState('')
    const [allSingers, setAllSingers] = useState(null)

    useEffect(() => {
        onSnapshot(collection(db, 'singers'), (snap) => {
            let temp = []
            snap.docs.forEach(doc => {
                temp.push(doc.data())
            })
            setAllSingers(temp)
        })
    }, [])

    function addSinger() {
        let singerId = uuidv4()
        if (!name || name !== '') {
            setDoc(doc(db, 'singers', singerId), { name, singerId })
        } else {
            alert('Try again later')
        }
    }
    function deleteSinger(singerId) {
        deleteDoc(doc(db, 'singers', singerId))
    }
    function editSinger(singerId) {
        const x = window.prompt('Write new name')
        if (x && x !== '') {
            setDoc(doc(db, 'singers', singerId), { name: x, singerId: singerId }, { merge: true })
        } else {
            alert('please provide a valid name')
        }
    }
    return (
        <div className="page_container">
            <buttn onClick={() => { handleShow() }} style={{ borderRadius: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1em', padding: '8px 15px', color: '#000', background: '#00b7ff', width: '55px', height: '55px', position: 'fixed', right: '20px', bottom: '20px' }} className='btn'>
                <IoAddOutline style={{ fontSize: '2em' }} />
            </buttn>
            <Modal className='addLinkModal' show={show} onHide={handleClose}>
                <Modal.Body>
                    <h2>New singer</h2>
                    <p style={{ margin: '0', color: '#6d6d6d' }}>Name of the singer</p>
                    <input value={name} onChange={(e) => { setName(e.target.value) }} type={'text'} style={{ borderRadius: '5px', fontSize: '0.9em', padding: '8px 15px', color: '#fff', background: '#151515', width: '-webkit-fill-available', marginBottom: '10px' }} />
                    <div style={{ display: 'flex', marginTop: '20px' }}>
                        <buttn onClick={handleClose} style={{ borderRadius: '50vh', display: 'flex', justifyContent: 'center', fontSize: '0.9em', padding: '8px 15px', color: '#00b7ff', border: '1px solid #00b7ff', margin: '10px 5px', width: '-webkit-fill-available', marginBottom: '0' }} className='btn'>
                            Cancel
                        </buttn>
                        <buttn onClick={() => {
                            let similar = allSingers.filter((singer) =>
                                singer.name.toLowerCase().includes(name.toLowerCase())
                            ).map(singer => singer.name)
                            if (similar?.length > 0) {
                                const q = window.confirm(`There is similar name already in the list\n${similar.join(" -- ")}\nDo you want to proceed?`)
                                if (q) {
                                    addSinger()
                                }
                            } else {
                                addSinger()
                            }
                            handleClose()
                        }} style={{ borderRadius: '50vh', display: 'flex', justifyContent: 'center', fontSize: '1em', padding: '8px 15px', color: '#000', background: '#00b7ff', margin: '10px 5px', width: '-webkit-fill-available', marginBottom: '0' }} className='btn'>
                            <IoAddOutline style={{ fontSize: '1.5em', marginRight: '7px' }} />Add
                        </buttn>
                    </div>
                </Modal.Body>
            </Modal>
            <h6 style={{ margin: '15px 0px', marginTop: '0px' }}>All singers</h6>
            <div className='card_container'>
                {
                    allSingers && allSingers.sort((a, b) => a.name.localeCompare(b.name)).map((singer, i) => {
                        return (
                            <div style={{ margin: '15px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', background: '#00b7ff30', padding: '10px 15px', borderRadius: '10px' }}>
                                <div style={{ display: 'flex' }}>
                                    <h1 style={{ margin: '0', marginRight: '20px' }}>{i + 1}</h1>
                                    <div>
                                        <p style={{ fontSize: '1em', fontWeight: '500', color: '#fff', margin: '0', marginBottom: '0' }}>{singer.name}</p>
                                    </div>
                                    <div style={{ flex: '1' }} />
                                    <p onClick={() => { deleteSinger(singer.singerId) }} style={{ fontSize: '1em', fontWeight: '500', color: 'red', margin: '0', marginBottom: '0', marginRight: '20px' }}>Delete</p>
                                    <p onClick={() => { editSinger(singer.singerId) }} style={{ fontSize: '1em', fontWeight: '500', color: 'green', margin: '0', marginBottom: '0' }}>Edit</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}