import React, { useEffect, useState, useRef } from "react";
import { BsBroadcast } from "react-icons/bs";
import { TbHandStop } from "react-icons/tb";
import { BiArrowBack } from "react-icons/bi";
import { AiOutlineLineChart } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDocs,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../config/fire";
import { IoAddOutline } from "react-icons/io5";
import { Button, Modal } from "react-bootstrap";
import { uuidv4 } from "@firebase/util";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import { TableToExcelReact } from "table-to-excel-react/lib";
import JudgeName from "../components/JudgeName";
import ChangeScore from "../components/ChangeScore";
import CustoMSelect from "../components/CustomSelect";
import CustoMSelectSong from "../components/CustomSelectSong";

function EventPage() {
  const [projectData, setProjectData] = useState(null);
  const [eventKey, setEventKey] = useState(null);
  const [allSongs, setAllSongs] = useState(null);
  const [allSingers, setAllSingers] = useState(null);
  const [alljudges, setAlljudges] = useState(null);
  const [isStatsVisible, setIsStatsVisible] = useState(false);
  const [participationgSingers, setParticipationgSingers] = useState(null);
  const [stats, setStats] = useState(null);
  const [isTrue, setIsTrue] = useState(false);
  const history = useHistory();
  const [singerRef, setSingerRef] = useState("");
  const [songRef, setSongRef] = useState("");
  const [selectedSingerScores, setSelectedSingerScores] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [finalStatsToShow, setFinalStatsToShow] = useState(null);

  const nameRef = useRef(null);
  const addressRef = useRef(null);
  const [eventDate, setEventDate] = useState(null);

  const [scoreOfselected, setScoreOfselected] = useState(null);

  const [showE, setShowE] = useState(false);
  const handleCloseE = () => setShowE(false);
  const handleShowE = () => setShowE(true);

  useEffect(() => {
    if (participationgSingers && stats) {
      readyData();
    }
  }, [participationgSingers, stats]);

  function readyData() {
    const finalData = [];
    participationgSingers &&
      participationgSingers.forEach((singer) => {
        let total = 0;
        let scoregivenby = 0;
        let temp = [];
        stats.forEach((stat) => {
          if (stat.forSinger === singer.singer) {
            temp.push(stat);
            scoregivenby += 1;
            for (const property in stat) {
              if (
                property !== "givenBy" &&
                property !== "forEvent" &&
                property !== "scoreKey" &&
                property !== "forSinger"
              ) {
                total += stat[property];
              }
            }
          }
        });
        finalData.push({
          score: Number(Number(total) / Number(scoregivenby)).toFixed(3),
          singer: singer.singer,
          temp,
        });
      });
    setFinalStatsToShow(finalData);
  }

  useEffect(() => {
    const queryString = window.location.search;
    const RetrivedchildKey = queryString.substring(1);
    setEventKey(RetrivedchildKey);
    onSnapshot(doc(db, "events", RetrivedchildKey), (snap) => {
      setProjectData(snap.data());
    });
    onSnapshot(
      query(
        collection(db, "events", RetrivedchildKey, "singers"),
        orderBy("place")
      ),
      (snap) => {
        let temp = [];
        snap.docs.forEach((doc) => {
          temp.push(doc.data());
        });
        setParticipationgSingers(temp);
      }
    );
    onSnapshot(collection(db, "singers"), (snap) => {
      let temp = [];
      snap.docs.forEach((doc) => {
        temp.push(doc.data());
      });
      setAllSingers(temp);
    });
    onSnapshot(collection(db, "songs"), (snap) => {
      let temp = [];
      snap.docs.forEach((doc) => {
        temp.push(doc.data());
      });
      setAllSongs(temp);
    });
    onSnapshot(
      query(collectionGroup(db, "user_details"), where("role", "==", "judge")),
      (snap) => {
        let temp = [];
        snap.docs.forEach((doc) => {
          temp.push(doc.data());
        });
        setAlljudges(temp);
      }
    );
    onSnapshot(collection(db, "events", RetrivedchildKey, "stats"), (snap) => {
      let temp = [];
      snap.docs.forEach((doc) => {
        temp.push(doc.data());
      });
      setStats(temp);
    });
  }, []);
  function addSingerToEvent(singer, song) {
    let participantId = uuidv4();
    setDoc(doc(db, "events", eventKey, "singers", participantId), {
      singer,
      song,
      participantId,
      place: participationgSingers.length,
    });
  }
  function deleteParticipant(singer) {
    getDocs(
      query(
        collection(db, "events", eventKey, "singers"),
        where("singer", "==", singer)
      )
    ).then((res) => {
      res.docs.forEach((doc) => {
        deleteDoc(doc.ref);
      });
    });
    console.log(singer);
  }
  function addJudgeToEvent(judge) {
    updateDoc(doc(db, "events", eventKey), {
      judges: arrayUnion(judge),
    });
  }
  function deleteJudge(judge) {
    updateDoc(doc(db, "events", eventKey), {
      judges: arrayRemove(judge),
    });
  }
  function startEvent() {
    updateDoc(doc(db, "events", eventKey), {
      isLive: true,
    });
  }
  function stopEvent() {
    updateDoc(doc(db, "events", eventKey), {
      isLive: false,
    });
  }
  function editVenue(obj) {
    updateDoc(doc(db, "events", eventKey), obj);
    handleCloseE();
  }
  function goUp(participantId, upperId) {
    console.log(participantId, upperId);
    setDoc(
      doc(db, "events", eventKey, "singers", upperId),
      { place: increment(+2) },
      { merge: true }
    );
    setDoc(
      doc(db, "events", eventKey, "singers", participantId),
      { place: increment(-2) },
      { merge: true }
    );
  }
  function goDown(participantId, lowerId) {
    setDoc(
      doc(db, "events", eventKey, "singers", participantId),
      { place: increment(+2) },
      { merge: true }
    );
    setDoc(
      doc(db, "events", eventKey, "singers", lowerId),
      { place: increment(-2) },
      { merge: true }
    );
    console.log(participantId, lowerId);
  }
  function deleteEvent() {
    const q = window.confirm("Do you really want to delete this event?");
    if (q) {
      const finalQ = window.confirm(
        "This will delete all the event data\nDo you really want to proceed?"
      );
      if (finalQ) {
        deleteDoc(doc(db, "events", eventKey));
        history.goBack();
      }
    }
  }
  function orderKeys(obj) {
    var keys = Object.keys(obj).sort(function keyOrder(k1, k2) {
      if (k1 < k2) return -1;
      else if (k1 > k2) return +1;
      else return 0;
    });

    var i,
      after = {};
    for (i = 0; i < keys.length; i++) {
      after[keys[i]] = obj[keys[i]];
      delete obj[keys[i]];
    }

    for (i = 0; i < keys.length; i++) {
      obj[keys[i]] = after[keys[i]];
    }
    return obj;
  }
  if (!projectData) {
    return (
      <div className="page_container">
        <div
          className="header"
          style={{
            top: "0px",
            height: "110px",
            alignItems: "start",
            paddingTop: "20px",
          }}
        >
          <BiArrowBack
            onClick={() => {
              history.goBack();
            }}
            style={{ color: "#00b7ff", fontSize: "1.5em", margin: "0px 20px" }}
          />
          <h1
            style={{
              margin: "0",
              color: "#00b7ff",
              fontWeight: "500",
              fontSize: "1.3em",
            }}
          >
            loading
          </h1>
          <div style={{ flex: "1" }}></div>
        </div>
      </div>
    );
  }
  if (isStatsVisible) {
    return (
      <div
        className="page_container"
        style={{ marginTop: "55px", minHeight: "calc(100vh - 55px)" }}
      >
        {scoreOfselected && (
          <ChangeScore setData={setScoreOfselected} data={scoreOfselected} />
        )}
        <div
          className="header"
          style={{
            top: "0px",
            height: "55px",
            alignItems: "start",
            paddingTop: "20px",
          }}
        >
          <BiArrowBack
            onClick={() => {
              setIsStatsVisible(false);
            }}
            style={{ color: "#00b7ff", fontSize: "1.5em", margin: "0px 20px" }}
          />
          <h1
            style={{
              margin: "0",
              color: "#00b7ff",
              fontWeight: "500",
              fontSize: "1.3em",
            }}
          >
            {projectData.eventName} | Stats
          </h1>
          <div style={{ flex: "1" }}></div>
        </div>
        {selectedSingerScores && (
          <div
            style={{
              position: "fixed",
              top: "55px",
              right: "20px",
              zIndex: "99",
            }}
          >
            <Button
              style={{
                marginTop: "15px",
                background: "#00b7ff",
                border: "none",
              }}
              onClick={() => {
                setSelectedSingerScores(null);
              }}
            >
              Close
            </Button>
          </div>
        )}
        {selectedSingerScores && (
          <div
            className="glass"
            style={{
              display: "flex",
              overflow: "scroll",
              paddingTop: "100px",
              justifyContent: "center",
              height: "100vh",
              width: "100vw",
              background: "#000000aa",
              position: "fixed",
              top: "0",
              left: "0",
            }}
          >
            <div>
              <center>
                <h3 style={{ color: "#00b7ff" }}>
                  Singer: {selectedSingerScores[0]?.forSinger} scores
                </h3>
              </center>
              {selectedSingerScores
                ?.sort((a, b) => {
                  const givenByA = a.givenBy.toUpperCase();
                  const givenByB = b.givenBy.toUpperCase();
                  if (givenByA < givenByB) {
                    return -1;
                  }
                  if (givenByA > givenByB) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((judge, i) => {
                  return (
                    <>
                      <center>
                        <h4 style={{ color: "#fff" }}>
                          Judge {i + 1}: <JudgeName uid={judge.givenBy} />{" "}
                          <br></br>
                          Singer: {judge.forSinger}
                        </h4>
                      </center>
                      <table style={{ marginBottom: "30px" }}>
                        <tbody
                          style={{
                            padding: "10px",
                            border: "1px solid #00b7ff30",
                            color: "#00b7ff",
                          }}
                        >
                          <tr
                            style={{
                              padding: "10px",
                              border: "1px solid #00b7ff30",
                              color: "#00b7ff",
                            }}
                          >
                            <th
                              style={{
                                padding: "10px",
                                border: "1px solid #00b7ff30",
                                color: "#00b7ff",
                              }}
                            >
                              Factor
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                border: "1px solid #00b7ff30",
                                color: "#00b7ff",
                              }}
                            >
                              Score
                              {auth.currentUser.uid ===
                                "Irwsg1x7AucPGB0u2UNf9ebMJGQ2" && (
                                <span
                                  style={{
                                    color: "yellow",
                                    marginLeft: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setScoreOfselected(judge);
                                  }}
                                >
                                  EDIT
                                </span>
                              )}
                            </th>
                          </tr>
                          {Object.keys(orderKeys(judge)).map((key) => {
                            if (
                              key !== "givenBy" &&
                              key !== "forEvent" &&
                              key !== "scoreKey" &&
                              key !== "forSinger"
                            ) {
                              return (
                                <tr
                                  style={{
                                    padding: "10px",
                                    border: "1px solid #00b7ff30",
                                    color: "#00b7ff",
                                  }}
                                >
                                  <td
                                    style={{
                                      padding: "10px",
                                      border: "1px solid #00b7ff30",
                                      color: "#00b7ff",
                                    }}
                                  >
                                    {key}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px 50px",
                                      border: "1px solid #00b7ff30",
                                      color: "#00b7ff",
                                    }}
                                  >
                                    {judge[key]} /
                                    {key === "Artist-audience engagement"
                                      ? "3"
                                      : key === "It factor"
                                      ? "2"
                                      : "5"}
                                  </td>
                                </tr>
                              );
                            }
                            return <></>;
                          })}
                        </tbody>
                      </table>
                    </>
                  );
                })}
              <br></br>
            </div>
          </div>
        )}
        <table id="table-to-xls" style={{ width: "-webkit-fill-available" }}>
          <tbody
            style={{
              padding: "10px",
              border: "1px solid #00b7ff",
              color: "#00b7ff",
            }}
          >
            <tr
              style={{
                padding: "10px",
                border: "1px solid #00b7ff",
                color: "#00b7ff",
              }}
            >
              <th
                style={{
                  padding: "10px",
                  border: "1px solid #00b7ff",
                  color: "#00b7ff",
                }}
              >
                Singer
              </th>
              <th
                style={{
                  padding: "10px",
                  border: "1px solid #00b7ff",
                  color: "#00b7ff",
                }}
              >
                Total
              </th>
            </tr>
            {finalStatsToShow &&
              finalStatsToShow
                .sort((a, b) => a.score.localeCompare(b.score))
                .reverse()
                .map((singer, i) => {
                  return (
                    <tr
                      style={{
                        padding: "10px",
                        border: "1px solid #00b7ff",
                        color: "#00b7ff",
                      }}
                    >
                      <td
                        onClick={() => {
                          setSelectedSingerScores(singer.temp);
                        }}
                        style={{
                          cursor: "pointer",
                          padding: "10px",
                          border: "1px solid #00b7ff",
                          color: "#00b7ff",
                        }}
                      >
                        {i + 1}
                        {") "}
                        {singer.singer}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          border: "1px solid #00b7ff",
                          color: "#00b7ff",
                        }}
                      >
                        {singer.score}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
        <TableToExcelReact
          table="table-to-xls"
          fileName={`${projectData?.eventName}`}
          sheet="sheet 1"
        >
          <center>
            <button
              style={{
                marginTop: "30px",
                border: "1px solid #00b7ff",
                color: "#00b7ff",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              {" "}
              Export to excel{" "}
            </button>
          </center>
        </TableToExcelReact>
      </div>
    );
  }
  return (
    <div
      className="page_container"
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: "calc(100vh - 55px)",
        marginTop: "55px",
      }}
    >
      <Modal className="addLinkModal" show={showE} onHide={handleCloseE}>
        <Modal.Body>
          <h2>New event</h2>
          <p style={{ margin: "0", color: "#6d6d6d" }}>Name</p>
          <input
            defaultValue={projectData.eventName}
            ref={nameRef}
            type={"text"}
            style={{
              borderRadius: "5px",
              fontSize: "0.9em",
              padding: "8px 15px",
              color: "#fff",
              background: "#151515",
              width: "-webkit-fill-available",
              marginBottom: "10px",
            }}
          />
          <p style={{ margin: "0", color: "#6d6d6d" }}>Address</p>
          <input
            defaultValue={projectData.address}
            ref={addressRef}
            type={"text"}
            style={{
              borderRadius: "5px",
              fontSize: "0.9em",
              padding: "8px 15px",
              color: "#fff",
              background: "#151515",
              width: "-webkit-fill-available",
              marginBottom: "10px",
            }}
          />
          <p style={{ margin: "0", color: "#6d6d6d" }}>Date</p>
          <input
            value={eventDate}
            onChange={(e) => {
              setEventDate(e.target.value);
            }}
            type={"date"}
            style={{
              borderRadius: "5px",
              fontSize: "0.9em",
              padding: "8px 15px",
              color: "#fff",
              background: "#151515",
              width: "-webkit-fill-available",
              marginBottom: "10px",
            }}
          />
          <div style={{ display: "flex", marginTop: "20px" }}>
            <buttn
              onClick={handleCloseE}
              style={{
                borderRadius: "50vh",
                display: "flex",
                justifyContent: "center",
                fontSize: "0.9em",
                padding: "8px 15px",
                color: "#00b7ff",
                border: "1px solid #00b7ff",
                margin: "10px 5px",
                width: "-webkit-fill-available",
                marginBottom: "0",
              }}
              className="btn"
            >
              Cancel
            </buttn>
            <buttn
              onClick={() => {
                editVenue({
                  eventName: nameRef.current.value,
                  address: addressRef.current.value,
                  date: String(new Date(eventDate)),
                });
                handleClose();
              }}
              style={{
                borderRadius: "50vh",
                display: "flex",
                justifyContent: "center",
                fontSize: "1em",
                padding: "8px 15px",
                color: "#000",
                background: "#00b7ff",
                margin: "10px 5px",
                width: "-webkit-fill-available",
                marginBottom: "0",
              }}
              className="btn"
            >
              <IoAddOutline style={{ fontSize: "1.5em", marginRight: "7px" }} />
              Add
            </buttn>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="addLinkModal" show={show} onHide={handleClose}>
        <Modal.Body>
          {isTrue ? (
            <>
              <h2>Add singer</h2>
              <p style={{ margin: "0", color: "#6d6d6d" }}>
                Select the singer you want to add
              </p>
              <CustoMSelect options={allSingers} setVal={setSingerRef} />
              {/* <select
                ref={singerRef}
                style={{
                  borderRadius: "5px",
                  fontSize: "0.9em",
                  padding: "8px 15px",
                  color: "#fff",
                  background: "#151515",
                  width: "-webkit-fill-available",
                  marginBottom: "10px",
                }}
              >
                <option>select singer</option>
                {allSingers &&
                  allSingers
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((singer, i) => {
                      return <option>{singer.name}</option>;
                    })}
              </select> */}
              <p style={{ margin: "0", color: "#6d6d6d" }}>
                Select the song this singer is going to perform.
              </p>
              <CustoMSelectSong setVal={setSongRef} />
            </>
          ) : (
            <>
              <h2>Add judge</h2>
              <p style={{ margin: "0", color: "#6d6d6d" }}>
                Select the judge you want to add
              </p>
              <select
                onChange={(e) => {
                  setSingerRef(e.target.value);
                }}
                style={{
                  borderRadius: "5px",
                  fontSize: "0.9em",
                  padding: "8px 15px",
                  color: "#fff",
                  background: "#151515",
                  width: "-webkit-fill-available",
                  marginBottom: "10px",
                }}
              >
                <option>select judge</option>
                {alljudges &&
                  alljudges
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((singer, i) => {
                      return <option value={singer.uid}>{singer.name}</option>;
                    })}
              </select>
            </>
          )}
          <div style={{ display: "flex", marginTop: "20px" }}>
            <buttn
              onClick={handleClose}
              style={{
                borderRadius: "50vh",
                display: "flex",
                justifyContent: "center",
                fontSize: "0.9em",
                padding: "8px 15px",
                color: "#00b7ff",
                border: "1px solid #00b7ff",
                margin: "10px 5px",
                width: "-webkit-fill-available",
                marginBottom: "0",
              }}
              className="btn"
            >
              Cancel
            </buttn>
            <buttn
              onClick={() => {
                if (isTrue) {
                  addSingerToEvent(singerRef, songRef);
                } else {
                  addJudgeToEvent(singerRef);
                }
                handleClose();
              }}
              style={{
                borderRadius: "50vh",
                display: "flex",
                justifyContent: "center",
                fontSize: "1em",
                padding: "8px 15px",
                color: "#000",
                background: "#00b7ff",
                margin: "10px 5px",
                width: "-webkit-fill-available",
                marginBottom: "0",
              }}
              className="btn"
            >
              <IoAddOutline style={{ fontSize: "1.5em", marginRight: "7px" }} />
              Add
            </buttn>
          </div>
        </Modal.Body>
      </Modal>
      <div style={{ maxWidth: "400px", width: "100%" }}>
        <div className="header" style={{ top: "0px" }}>
          <BiArrowBack
            onClick={() => {
              history.goBack();
            }}
            style={{ color: "#00b7ff", fontSize: "1.5em", margin: "0px 20px" }}
          />
          <h1
            style={{
              margin: "0",
              color: "#00b7ff",
              fontWeight: "500",
              fontSize: "1.3em",
            }}
          >
            {projectData.eventName}
          </h1>
          <div style={{ flex: "1" }}></div>
          <DropdownButton
            id="dropdown-basic-button"
            title="Make changes"
            className="drop"
            style={{ marginRight: "10px" }}
          >
            <Dropdown.Item
              onClick={() => {
                setIsTrue(true);
                handleShow();
              }}
            >
              Add singer
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setIsTrue(false);
                handleShow();
              }}
            >
              Add judge
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                handleShowE();
              }}
            >
              Edit venue
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                deleteEvent();
              }}
            >
              Delete venue
            </Dropdown.Item>
          </DropdownButton>
        </div>
        {projectData?.isLive ? (
          <buttn
            onClick={() => {
              stopEvent();
            }}
            style={{
              borderRadius: "50vh",
              display: "flex",
              justifyContent: "center",
              fontSize: "1em",
              padding: "8px 15px",
              background: "#ff050533",
              color: "red",
              width: "-webkit-fill-available",
              marginBottom: "10px",
            }}
            className="btn"
          >
            <TbHandStop style={{ fontSize: "1.5em", marginRight: "10px" }} />
            Stop event
          </buttn>
        ) : (
          <button
            onClick={() => {
              startEvent();
            }}
            style={{
              borderRadius: "50vh",
              display: "flex",
              justifyContent: "center",
              fontSize: "1em",
              padding: "8px 15px",
              color: "#000",
              background: "#00b7ff",
              width: "-webkit-fill-available",
              marginBottom: "10px",
            }}
            className="btn"
          >
            <BsBroadcast style={{ fontSize: "1.5em", marginRight: "10px" }} />
            Start this event
          </button>
        )}
        <button
          onClick={() => {
            setIsStatsVisible(true);
          }}
          style={{
            borderRadius: "50vh",
            display: "flex",
            justifyContent: "center",
            fontSize: "1em",
            padding: "8px 15px",
            color: "#000",
            background: "#00b7ff",
            width: "-webkit-fill-available",
            marginBottom: "10px",
          }}
          className="btn"
        >
          <AiOutlineLineChart
            style={{ fontSize: "1.5em", marginRight: "10px" }}
          />
          Stats of this event
        </button>
        <h6 style={{ margin: "15px 0px" }}>Singers</h6>
        {participationgSingers &&
          participationgSingers.map((singer, i) => {
            return (
              <div
                style={{
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  background: "#00b7ff30",
                  padding: "10px 15px",
                  borderRadius: "10px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p
                      onClick={() => {
                        if (i !== 0) {
                          goUp(
                            singer?.participantId,
                            participationgSingers[i - 1].participantId
                          );
                        }
                      }}
                      style={{
                        fontSize: "1em",
                        fontWeight: "500",
                        color: "green",
                        margin: "0",
                        marginBottom: "0",
                        marginRight: "5px",
                      }}
                    >
                      <VscTriangleUp style={{ fontSize: "1.5em" }} />
                    </p>
                    <p
                      onClick={() => {
                        if (i !== participationgSingers.length - 1) {
                          goDown(
                            singer?.participantId,
                            participationgSingers[i + 1].participantId
                          );
                        }
                      }}
                      style={{
                        fontSize: "1em",
                        fontWeight: "500",
                        color: "red",
                        margin: "0",
                        marginBottom: "0",
                        marginRight: "10px",
                      }}
                    >
                      <VscTriangleDown style={{ fontSize: "1.5em" }} />
                    </p>
                  </div>
                  <h1 style={{ margin: "0", marginRight: "20px" }}>{i + 1}</h1>
                  <div>
                    <p
                      style={{
                        fontSize: "1em",
                        fontWeight: "500",
                        color: "#fff",
                        margin: "0",
                        marginBottom: "0",
                      }}
                    >
                      {singer.singer}
                    </p>
                    <p
                      style={{
                        fontSize: "0.8em",
                        fontWeight: "500",
                        color: "#ccc",
                        margin: "0",
                        marginBottom: "0",
                      }}
                    >
                      {singer.song}
                    </p>
                  </div>
                  <div style={{ flex: "1" }} />
                  <div
                    onClick={() => {
                      deleteParticipant(singer.singer);
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.8em",
                        fontWeight: "500",
                        color: "red",
                        margin: "0",
                        marginBottom: "0",
                        marginLeft: "40px",
                      }}
                    >
                      Delete
                    </p>
                  </div>
                </div>
              </div>
            );
          })}

        <h6 style={{ margin: "15px 0px" }}>Judges</h6>
        {alljudges &&
          alljudges.map((judge) => {
            if (!projectData?.judges?.includes(judge.uid)) {
              return <></>;
            }
            return (
              <div
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={judge.thumbnail}
                  style={{
                    height: "60px",
                    width: "60px",
                    borderRadius: "25px",
                    background: "#000",
                    marginRight: "10px",
                  }}
                />
                <div>
                  <p
                    style={{
                      fontSize: "1em",
                      fontWeight: "500",
                      color: "#fff",
                      margin: "0",
                      marginBottom: "0",
                    }}
                  >
                    {judge.name}
                  </p>
                  <p
                    style={{
                      fontSize: "0.8em",
                      fontWeight: "500",
                      color: "#ccc",
                      margin: "0",
                      marginBottom: "0",
                    }}
                  >
                    {judge.bio}
                  </p>
                </div>
                <div style={{ flex: "1" }} />
                <div style={{ flex: "1" }} />
                <div>
                  <p
                    onClick={() => {
                      deleteJudge(judge.uid);
                    }}
                    style={{
                      fontSize: "1em",
                      fontWeight: "500",
                      color: "red",
                      margin: "0",
                      marginBottom: "0",
                      marginLeft: "40px",
                    }}
                  >
                    Remove
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default EventPage;
