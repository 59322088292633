import React, { useState, useEffect, useRef } from "react";
import { IoAddOutline, IoCloseSharp } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import {
  collection,
  doc,
  onSnapshot,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { auth, db } from "../config/fire";
import { uuidv4 } from "@firebase/util";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import moment from "moment/moment";
export default function Home() {
  const nameRef = useRef(null);
  const addressRef = useRef(null);
  const [eventDate, setEventDate] = useState(null);
  const [allEvents, setAllEvents] = useState(null);
  const [q, setQ] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filter, setFilter] = useState("Upcoming events");
  useEffect(() => {
    onSnapshot(collection(db, "events"), (snap) => {
      let temp = [];
      snap.docs.forEach((doc) => {
        temp.push(doc.data());
      });
      setAllEvents(temp);
    });
  }, []);

  function addEvent(obj) {
    let eventId = uuidv4();
    setDoc(doc(db, "events", eventId), { ...obj, eventId });
  }
  return (
    <div className="page_container">
      <buttn
        onClick={() => {
          handleShow();
        }}
        style={{
          borderRadius: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "1em",
          padding: "8px 15px",
          color: "#000",
          background: "#00b7ff",
          width: "55px",
          height: "55px",
          position: "fixed",
          right: "20px",
          bottom: "20px",
        }}
        className="btn"
      >
        <IoAddOutline style={{ fontSize: "2em" }} />
      </buttn>
      <div className="searchbar_container">
        <div
          style={{
            width: "min-content",
            border: "2px solid #00b7ff70",
            borderRadius: "50vh",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <FaSearch
            style={{ color: "#00b7ff", fontSize: "1.1em", margin: "0px 10px" }}
          />
          <input
            type={"text"}
            value={q}
            onChange={(e) => {
              setQ(e.target.value);
            }}
            placeholder="Search an event..."
            className="search_input"
          />
          {q !== "" && (
            <IoCloseSharp
              style={{
                color: "#00b7ff",
                fontSize: "1.5em",
                margin: "0px 10px",
                right: "5px",
                position: "absolute",
              }}
              onClick={() => {
                setQ("");
              }}
            />
          )}
        </div>
      </div>
      <Modal className="addLinkModal" show={show} onHide={handleClose}>
        <Modal.Body>
          <h2>New event</h2>
          <p style={{ margin: "0", color: "#6d6d6d" }}>Name</p>
          <input
            ref={nameRef}
            type={"text"}
            style={{
              borderRadius: "5px",
              fontSize: "0.9em",
              padding: "8px 15px",
              color: "#fff",
              background: "#151515",
              width: "-webkit-fill-available",
              marginBottom: "10px",
            }}
          />
          <p style={{ margin: "0", color: "#6d6d6d" }}>Address</p>
          <input
            ref={addressRef}
            type={"text"}
            style={{
              borderRadius: "5px",
              fontSize: "0.9em",
              padding: "8px 15px",
              color: "#fff",
              background: "#151515",
              width: "-webkit-fill-available",
              marginBottom: "10px",
            }}
          />
          <p style={{ margin: "0", color: "#6d6d6d" }}>Date</p>
          <input
            value={eventDate}
            onChange={(e) => {
              setEventDate(e.target.value);
            }}
            type={"date"}
            style={{
              borderRadius: "5px",
              fontSize: "0.9em",
              padding: "8px 15px",
              color: "#fff",
              background: "#151515",
              width: "-webkit-fill-available",
              marginBottom: "10px",
            }}
          />
          <div style={{ display: "flex", marginTop: "20px" }}>
            <buttn
              onClick={handleClose}
              style={{
                borderRadius: "50vh",
                display: "flex",
                justifyContent: "center",
                fontSize: "0.9em",
                padding: "8px 15px",
                color: "#00b7ff",
                border: "1px solid #00b7ff",
                margin: "10px 5px",
                width: "-webkit-fill-available",
                marginBottom: "0",
              }}
              className="btn"
            >
              Cancel
            </buttn>
            <buttn
              onClick={() => {
                addEvent({
                  eventName: nameRef.current.value,
                  address: addressRef.current.value,
                  date: String(new Date(eventDate)),
                  createdBy: auth.currentUser.uid,
                });
                handleClose();
              }}
              style={{
                borderRadius: "50vh",
                display: "flex",
                justifyContent: "center",
                fontSize: "1em",
                padding: "8px 15px",
                color: "#000",
                background: "#00b7ff",
                margin: "10px 5px",
                width: "-webkit-fill-available",
                marginBottom: "0",
              }}
              className="btn"
            >
              <IoAddOutline style={{ fontSize: "1.5em", marginRight: "7px" }} />
              Add
            </buttn>
          </div>
        </Modal.Body>
      </Modal>
      <div
        style={{
          display: "flex",
          marginBottom: "15px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h6 style={{ margin: "0", fontSize: "1.3em" }}>Events</h6>
        <select
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          style={{
            fontSize: "0.9em",
            padding: "10px 15px",
            color: "#fff",
            background: "#000",
            width: "fit-content",
            border: "2px solid #00b7ff70",
            borderRadius: "50vh",
          }}
        >
          <option>Upcoming events</option>
          <option>Past events</option>
        </select>
      </div>
      <div className="card_container">
        {allEvents &&
          q === "" &&
          filter === "Upcoming events" &&
          allEvents
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .map((data) => {
              console.log(
                moment(data.date).diff(moment(new Date())),
                data.eventName
              );
              if (
                filter === "Upcoming events" &&
                moment(data.date).diff(moment(new Date())) < -73476405
              ) {
                console.log(moment(data.date).diff(moment(new Date())));
                return <></>;
              }
              if (
                filter === "Past events" &&
                moment(data.date).diff(moment(new Date())) > -73476405
              ) {
                return <></>;
              }
              if (
                auth.currentUser.uid !== "Irwsg1x7AucPGB0u2UNf9ebMJGQ2" &&
                data?.createdBy !== auth.currentUser.uid
              ) {
                return <></>;
              }
              return (
                <div
                  style={{
                    borderRadius: "10px",
                    background: "#101010",
                    width: "-webkit-fill-available",
                    padding: "24px",
                    height: "max-content",
                    margin: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ flex: "1" }}>
                      <h5>Name: {data.eventName}</h5>
                      <h6 style={{ color: "#ccc" }}>Venue: {data.address}</h6>
                      <h6 style={{ color: "#ccc" }}>
                        Date:{" "}
                        {new Date(data.date).toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })}
                      </h6>
                    </div>
                  </div>
                  <Link
                    to={{
                      pathname: `/event`,
                      search: `?${data.eventId}`,
                      state: { fromDashboard: true },
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      style={{
                        width: "-webkit-fill-available",
                        backgroundColor: "#00b7ff",
                        color: "#000",
                        marginTop: "10px",
                      }}
                    >
                      View more
                    </Button>
                  </Link>
                </div>
              );
            })}
        {allEvents &&
          q === "" &&
          filter === "Past events" &&
          allEvents
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .reverse()
            .map((data) => {
              console.log(
                moment(data.date).diff(moment(new Date())),
                data.eventName
              );
              if (
                filter === "Upcoming events" &&
                moment(data.date).diff(moment(new Date())) < -73476405
              ) {
                console.log(moment(data.date).diff(moment(new Date())));
                return <></>;
              }
              if (
                filter === "Past events" &&
                moment(data.date).diff(moment(new Date())) > -73476405
              ) {
                return <></>;
              }
              if (
                auth.currentUser.uid !== "Irwsg1x7AucPGB0u2UNf9ebMJGQ2" &&
                data?.createdBy !== auth.currentUser.uid
              ) {
                return <></>;
              }
              return (
                <div
                  style={{
                    borderRadius: "10px",
                    background: "#101010",
                    width: "-webkit-fill-available",
                    padding: "24px",
                    height: "max-content",
                    margin: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ flex: "1" }}>
                      <h5>Name: {data.eventName}</h5>
                      <h6 style={{ color: "#ccc" }}>Venue: {data.address}</h6>
                      <h6 style={{ color: "#ccc" }}>
                        Date:{" "}
                        {new Date(data.date).toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })}
                      </h6>
                    </div>
                  </div>
                  <Link
                    to={{
                      pathname: `/event`,
                      search: `?${data.eventId}`,
                      state: { fromDashboard: true },
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      style={{
                        width: "-webkit-fill-available",
                        backgroundColor: "#00b7ff",
                        color: "#000",
                        marginTop: "10px",
                      }}
                    >
                      View more
                    </Button>
                  </Link>
                </div>
              );
            })}
        {allEvents &&
          q !== "" &&
          allEvents
            .filter((event) =>
              event.eventName.toLowerCase().includes(q.toLowerCase())
            )
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .sort((a, b) => a.eventName.localeCompare(b.eventName))
            .map((data) => {
              if (
                auth.currentUser.uid !== "Irwsg1x7AucPGB0u2UNf9ebMJGQ2" &&
                data?.createdBy !== auth.currentUser.uid
              ) {
                return <></>;
              }
              return (
                <div
                  style={{
                    borderRadius: "10px",
                    background: "#101010",
                    width: "-webkit-fill-available",
                    padding: "24px",
                    height: "max-content",
                    margin: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ flex: "1" }}>
                      <h5>Name: {data.eventName}</h5>
                      <h6 style={{ color: "#ccc" }}>Venue: {data.address}</h6>
                      <h6 style={{ color: "#ccc" }}>
                        Date:{" "}
                        {new Date(data.date).toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })}
                      </h6>
                    </div>
                  </div>
                  <Link
                    to={{
                      pathname: `/event`,
                      search: `?${data.eventId}`,
                      state: { fromDashboard: true },
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      style={{
                        width: "-webkit-fill-available",
                        backgroundColor: "#00b7ff",
                        color: "#000",
                        marginTop: "10px",
                      }}
                    >
                      View more
                    </Button>
                  </Link>
                </div>
              );
            })}
      </div>
    </div>
  );
}
