import React, { useState } from "react";

const SelectWithTextInput = ({ options, setVal }) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setVal(value);

    const filtered = options.filter((option) =>
      option.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
    setSelectedOption(null); // Clear selected option when input value changes
  };

  const handleOptionSelect = (option) => {
    setInputValue(option);
    setVal(option);
    setSelectedOption(option);
    setFilteredOptions([]);
  };

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => {
          setIsVisible(true);
        }}
        placeholder="Select Singer"
        style={{
          borderRadius: "5px",
          fontSize: "0.9em",
          padding: "8px 15px",
          color: "#fff",
          background: "#151515",
          width: "-webkit-fill-available",
          marginBottom: "10px",
        }}
      />
      <ul
        style={{
          position: "absolute",
          background: "#6d6d6d",
          padding: "0px 30px",
          maxHeight: isVisible ? "400px" : "0px",
          overflow: "scroll",
        }}
      >
        {filteredOptions
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((option, index) => (
            <li
              style={{ color: "#fff", cursor: "pointer" }}
              key={index}
              onClick={() => {
                handleOptionSelect(option.name);
                setIsVisible(false);
              }}
            >
              {option.name}
            </li>
          ))}
      </ul>
    </div>
  );
};

const CustoMSelect = ({ options, setVal }) => {
  return (
    <div>
      {options && <SelectWithTextInput options={options} setVal={setVal} />}
    </div>
  );
};

export default CustoMSelect;
