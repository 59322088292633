import {
  collectionGroup,
  getDoc,
  where,
  query,
  getDocs,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../config/fire";

function JudgeName({ uid }) {
  const [data, setData] = useState("");
  useEffect(() => {
    getDocs(
      query(collectionGroup(db, "user_details"), where("uid", "==", uid))
    ).then((res) => {
      res.docs.forEach((item) => {
        setData(item.data());
      });
    });
  }, []);

  return <span>{data?.name}</span>;
}

export default JudgeName;
