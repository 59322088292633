import {
  arrayRemove,
  arrayUnion,
  collectionGroup,
  doc,
  getDocs,
  onSnapshot,
  setDoc,
  where,
} from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { db } from "../config/fire";
import { Spinner } from "react-bootstrap";
export default function Admins() {
  const [allAdminsUids, setAllAdminsUids] = useState(null);
  const [allAdmins, setAllAdmins] = useState(null);
  const [verified, setVerified] = useState(null);
  useEffect(() => {
    onSnapshot(doc(db, "admin", "allAdmins"), (res) => {
      setAllAdminsUids(res.data().uids);
    });
    onSnapshot(doc(db, "admin", "verifiedAdmins"), (res) => {
      setVerified(res.data()?.uids);
    });
  }, []);
  useEffect(() => {
    if (allAdminsUids) {
      getDocs(collectionGroup(db, "user_details")).then((res) => {
        let temp = [];
        res.docs.forEach((doc, i) => {
          if (allAdminsUids.includes(doc.data().uid)) {
            temp.push(doc.data());
          }
        });
        setAllAdmins(temp);
      });
    }
  }, [allAdminsUids]);

  function addAdmin(uid) {
    setDoc(
      doc(db, "admin", "verifiedAdmins"),
      {
        uids: arrayUnion(uid),
      },
      { merge: true }
    );
  }
  function removeAdmin(uid) {
    if (uid !== "Irwsg1x7AucPGB0u2UNf9ebMJGQ2") {
      setDoc(
        doc(db, "admin", "verifiedAdmins"),
        {
          uids: arrayRemove(uid),
        },
        { merge: true }
      );
    }
  }
  //.sort((a, b) => a.name.localeCompare(b.name))
  if (!allAdmins || !verified) {
    return (
      <div className="page_container">
        <Spinner style={{ color: "#007bff" }} />
      </div>
    );
  }
  return (
    <div className="page_container">
      <h6 style={{ margin: "15px 0px", marginTop: "0px" }}>All Admins</h6>
      <div className="card_container">
        {allAdmins &&
          allAdmins.map((admin, i) => {
            console.log(admin);
            return (
              <div
                style={{
                  margin: "15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  background: "#00b7ff30",
                  padding: "10px 15px",
                  borderRadius: "10px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <h1 style={{ margin: "0", marginRight: "20px" }}>{i + 1}</h1>
                  <div>
                    <p
                      style={{
                        fontSize: "1em",
                        fontWeight: "500",
                        color: "#fff",
                        margin: "0",
                        marginBottom: "0",
                      }}
                    >
                      {admin.name}
                    </p>
                  </div>
                  <div style={{ flex: "1" }} />
                  {!verified.includes(admin.uid) ? (
                    <p
                      onClick={() => {
                        addAdmin(admin.uid);
                      }}
                      style={{
                        fontSize: "1em",
                        fontWeight: "500",
                        color: "green",
                        margin: "0",
                        marginBottom: "0",
                        marginRight: "20px",
                      }}
                    >
                      Add
                    </p>
                  ) : (
                    <p
                      onClick={() => {
                        removeAdmin(admin.uid);
                      }}
                      style={{
                        fontSize: "1em",
                        fontWeight: "500",
                        color: "red",
                        margin: "0",
                        marginBottom: "0",
                      }}
                    >
                      Remove
                    </p>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
