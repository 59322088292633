import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { arrayUnion, doc, setDoc } from "firebase/firestore";
import React from "react";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { SiGamejolt } from "react-icons/si";
import { useHistory } from "react-router-dom";
import { auth } from "../../config/fire";
import { db } from "../../config/fire";
import logo from "./logoadmin.png";

function Login() {
  const [register, setRegister] = useState(false);
  const [userName, setUserName] = useState("");
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  async function login() {
    if (email !== "" && password !== "") {
      await signInWithEmailAndPassword(auth, email, password)
        .then(() => {})
        .catch((error) => {
          switch (error.message) {
            case "Firebase: Error (auth/invalid-email).":
              alert("You entered invalid email-id");
              break;
            case "Firebase: Error (auth/wrong-password).":
              alert("You entered wrong password");
              break;
            default:
              alert("Something went wrong try again later", error.message);
              break;
          }
        });
    } else {
      alert("Email or Password not provided");
    }
  }
  async function signup() {
    if (
      email !== "" &&
      password !== "" &&
      password.length > 6 &&
      userName !== ""
    ) {
      await createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          setDoc(
            doc(db, "users", auth.currentUser.uid, "user_details", "info"),
            {
              name: userName,
              uid: auth.currentUser.uid,
              bio: bio,
              thumbnail:
                "https://questhowth.ie/wp-content/uploads/2018/04/user-placeholder.png",
              email: email,
            }
          ).then(() => {
            setDoc(
              doc(db, "admin", "allAdmins"),
              {
                uids: arrayUnion(auth.currentUser.uid),
              },
              { merge: true }
            );
            alert(`Account created with email ${auth.currentUser.email}`);
          });
        })
        .catch((error) => {
          console.log(error);
          alert(error.message, "error while register");
        });
    } else if (userName === "") {
      alert("User name not entered");
    } else if (email === "") {
      alert("Email not entered");
    } else if (password.length < 6) {
      alert("password must be at least 6 characters");
    } else {
      alert("Oops, an error occurred try again later");
    }
  }

  useEffect(() => {
    setUserName("");
    setEmail("");
    setPassword("");
  }, [register]);
  function startForgotPassword() {
    sendPasswordResetEmail(auth, email).then(() => {
      alert("sent");
    });
  }
  if (isForgotPassword) {
    return (
      <div
        className="login_bg"
        style={{
          background: "linear-gradient(180deg, black, #042836)",
          width: "100vw",
          minHeight: "100vh",
          maxHeight: "max-content",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="login_form_bg"
          style={{
            textAlign: "center",
            width: "90vw",
            maxWidth: "400px",
            height: "max-content",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <h1
            style={{
              textAlign: "left",
              padding: "0px 10px",
              marginBottom: "25px",
              display: "flex",
              alignItems: "center",
              color: "#00b7ff",
              fontSize: "1.7em",
            }}
          >
            <SiGamejolt
              style={{ fontSize: "1em", color: "#00b7ff", marginRight: "10px" }}
            />
            Forgot password?
          </h1>
          <div>
            <input
              type="email"
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              style={{
                width: "95%",
                height: "45px",
                marginBottom: "10px",
                background: "none",
                padding: "10px",
                border: "1px solid #ffffff2e",
                borderRadius: "10px",
                color: "white",
              }}
            />
          </div>
          <button
            style={{
              margin: "20px 5px",
              padding: "10px 20px",
              borderRadius: "10px",
              width: "130px",
              fontWeight: "bold",
              border: "none",
              backgroundColor: "#00b7ff",
              color: "black",
            }}
            onClick={() => {
              startForgotPassword();
            }}
          >
            Send link
          </button>
          <p
            style={{ color: "white", cursor: "pointer", margin: "10px 5px" }}
            onClick={() => {
              setIsForgotPassword(false);
            }}
          >
            Already a user? Click here.
          </p>
        </div>
      </div>
    );
  }
  return (
    <div
      className="login_bg"
      style={{
        background: "linear-gradient(180deg, black, #042836)",
        width: "100vw",
        minHeight: "100vh",
        maxHeight: "max-content",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {register === false ? (
        <div
          className="login_form_bg"
          style={{
            textAlign: "center",
            width: "90vw",
            maxWidth: "400px",
            height: "max-content",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <center>
            <img
              src={logo}
              style={{
                marginBottom: "20px",
                maxWidth: "300px",
                maxHeight: "100px",
              }}
            />
          </center>
          <div>
            <input
              type="email"
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              style={{
                width: "95%",
                height: "45px",
                marginBottom: "10px",
                background: "none",
                padding: "10px",
                border: "1px solid #ffffff2e",
                borderRadius: "10px",
                color: "white",
              }}
            />
          </div>
          <div>
            <input
              placeholder="password"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              style={{
                width: "95%",
                height: "45px",
                background: "none",
                padding: "10px",
                border: "1px solid #ffffff2e",
                borderRadius: "10px",
                color: "white",
              }}
            />
          </div>
          <button
            style={{
              margin: "20px 5px",
              padding: "10px 20px",
              borderRadius: "10px",
              width: "130px",
              fontWeight: "bold",
              border: "none",
              backgroundColor: "#00b7ff",
              color: "black",
            }}
            onClick={() => {
              login();
            }}
          >
            Login
          </button>
          <p
            style={{ color: "white", cursor: "pointer", margin: "10px 5px" }}
            onClick={() => {
              setRegister(true);
            }}
          >
            New user? Register here.
          </p>
          <p
            style={{ color: "white", cursor: "pointer", margin: "10px 5px" }}
            onClick={() => {
              setIsForgotPassword(true);
            }}
          >
            Forgot password? Click here.
          </p>
        </div>
      ) : (
        <div
          className="login_form_bg"
          style={{
            textAlign: "left",
            width: "90vw",
            maxWidth: "400px",
            height: "max-content",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <h1
            style={{
              textAlign: "left",
              marginBottom: "20px",
              color: "#00b7ff",
              fontSize: "1.7em",
            }}
          >
            Create a new account
          </h1>
          <p
            style={{
              margin: "0",
              color: "#ccc",
              marginTop: "5px",
              marginBottom: "3px",
            }}
          >
            User name
          </p>
          <input
            type={"text"}
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            style={{
              borderRadius: "5px",
              fontSize: "1.1em",
              padding: "8px 15px",
              color: "#fff",
              textTransform: "capitalize",
              background: "#00000000",
              border: "1px solid #ffffff2e",
              width: "-webkit-fill-available",
              marginBottom: "10px",
            }}
          />
          <p
            style={{
              margin: "0",
              color: "#ccc",
              marginTop: "5px",
              marginBottom: "3px",
            }}
          >
            Email
          </p>
          <input
            type={"email"}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            style={{
              borderRadius: "5px",
              fontSize: "1.1em",
              padding: "8px 15px",
              color: "#fff",
              background: "#00000000",
              border: "1px solid #ffffff2e",
              width: "-webkit-fill-available",
              marginBottom: "10px",
            }}
          />
          <p
            style={{
              margin: "0",
              color: "#ccc",
              marginTop: "5px",
              marginBottom: "3px",
            }}
          >
            Password
          </p>
          <input
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            style={{
              borderRadius: "5px",
              fontSize: "1.1em",
              padding: "8px 15px",
              color: "#fff",
              background: "#00000000",
              border: "1px solid #ffffff2e",
              width: "-webkit-fill-available",
              marginBottom: "10px",
            }}
          />
          <center>
            <button
              style={{
                margin: "10px 5px",
                padding: "10px",
                borderRadius: "10px",
                width: "100px",
                fontWeight: "bold",
                border: "1px solid #00b7ff",
                background: "none",
                color: "#00b7ff",
              }}
              onClick={() => {
                signup();
              }}
            >
              Register
            </button>
            <p
              style={{ color: "white", cursor: "pointer", margin: "10px 5px" }}
              onClick={() => {
                setRegister(false);
              }}
            >
              Already registered? Login here.
            </p>
          </center>
        </div>
      )}
    </div>
  );
}

export default Login;
