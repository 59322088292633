import { collection, doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { IoAddOutline } from "react-icons/io5";
import { db } from "../config/fire";

function ChangeScore({ data, setData }) {
  const [scoreOfselected, setScoreOfselected] = useState(null);

  useEffect(() => {
    setScoreOfselected(data);
  }, []);
  //   function giveScore(singer, scoreObj) {
  //     addDoc(collection(db, 'events', eventKey, 'stats'), { ...scoreObj, givenBy: auth.currentUser.uid, forEvent: eventKey, forSinger: singer.singer })
  //     setShow(false)
  // }
  function change() {
    updateDoc(
      doc(
        db,
        "events",
        scoreOfselected.forEvent,
        "stats",
        scoreOfselected.scoreKey
      ),
      scoreOfselected
    )
      .then((res) => {
        alert("DONE!!");
      })
      .catch((err) => {
        alert(err);
      });
  }
  if (!scoreOfselected) {
    return <Spinner></Spinner>;
  }
  return (
    <Modal
      className="addLinkModal"
      show={data ? true : false}
      onHide={() => {
        setData(null);
      }}
    >
      <Modal.Body>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            color: "#fff",
          }}
        >
          <p style={{ margin: "0", color: "#fff" }}>TECHNICAL</p>
          <div>
            <input
              type={"number"}
              value={scoreOfselected["TECHNICAL"]}
              onChange={(e) => {
                if (e.target.value <= 50) {
                  setScoreOfselected({
                    ...scoreOfselected,
                    ["TECHNICAL"]: Number(e.target.value),
                  });
                } else {
                  e.target.value = 0;
                }
              }}
              style={{
                borderRadius: "5px",
                fontSize: "0.9em",
                padding: "5px",
                color: "#fff",
                background: "#151515",
                width: "-webkit-fill-available",
                marginBottom: "5px",
                width: "80px",
                textAlign: "center",
              }}
            />
            {"  "}/50
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            color: "#fff",
          }}
        >
          <p style={{ margin: "0", color: "#fff" }}>ARTISTRY</p>
          <div>
            <input
              type={"number"}
              value={scoreOfselected["ARTISTRY"]}
              onChange={(e) => {
                if (e.target.value <= 50) {
                  setScoreOfselected({
                    ...scoreOfselected,
                    ["ARTISTRY"]: Number(e.target.value),
                  });
                } else {
                  e.target.value = 0;
                }
              }}
              style={{
                borderRadius: "5px",
                fontSize: "0.9em",
                padding: "5px",
                color: "#fff",
                background: "#151515",
                width: "-webkit-fill-available",
                marginBottom: "5px",
                width: "80px",
                textAlign: "center",
              }}
            />
            {"  "}/50
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "20px" }}>
          <buttn
            onClick={() => {
              setData(null);
            }}
            style={{
              borderRadius: "50vh",
              display: "flex",
              justifyContent: "center",
              fontSize: "0.9em",
              padding: "5px",
              color: "#00b7ff",
              border: "1px solid #00b7ff",
              margin: "10px 5px",
              width: "-webkit-fill-available",
              marginBottom: "0",
            }}
            className="btn"
          >
            cancel
          </buttn>
          <buttn
            onClick={() => {
              let temp = 0;
              for (const property in scoreOfselected) {
                temp += 1;
                // console.log(`${property}: ${scoreOfselected[property]}`);
              }
              if (temp < 2) {
                alert("Please provide all the scores");
              } else {
                change();
              }
            }}
            style={{
              borderRadius: "50vh",
              display: "flex",
              justifyContent: "center",
              fontSize: "1em",
              padding: "5px",
              color: "#000",
              background: "#00b7ff",
              margin: "10px 5px",
              width: "-webkit-fill-available",
              marginBottom: "0",
            }}
            className="btn"
          >
            <IoAddOutline style={{ fontSize: "1.5em", marginRight: "7px" }} />
            submit
          </buttn>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default ChangeScore;
