import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "./logoadmin.png";
import { FaMusic, FaUsers } from "react-icons/fa";
import "../pages/pages.css";
import { BiTimeFive } from "react-icons/bi";
import { RiAdminFill } from "react-icons/ri";
import { FaLock } from "react-icons/fa";
import { signOut } from "firebase/auth";
import { auth } from "../config/fire";
export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
function Header() {
  const location = useLocation();
  // function logOut() {
  //     return signOut(auth)
  // }
  return (
    <>
      {getWindowDimensions().width < 600 && (
        <div className="header">
          <img
            src={logo}
            style={{ height: "40px", width: "60px", marginLeft: "15px" }}
          />
          <h1
            style={{
              margin: "0",
              color: "#00b7ff",
              fontWeight: "500",
              fontSize: "1em",
              minWidth: "max-content",
            }}
          >
            IKF Admin
          </h1>
          <div style={{ flex: "1" }}></div>
          <div
            style={{
              background: "#00b7ff",
              fontSize: "0.5em",
              maxWidth: "min-content",
              color: "#000",
              padding: "5px",
              borderRadius: "5px",
              margin: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              signOut(auth);
            }}
          >
            Logout
          </div>
        </div>
      )}
      {location.pathname !== "/event" && (
        <div
          className="header"
          style={{ top: getWindowDimensions().width < 600 ? "50px" : "0px" }}
        >
          {getWindowDimensions().width > 600 && (
            <>
              <img
                src={logo}
                style={{ height: "40px", width: "60px", marginLeft: "15px" }}
              />
              <h1
                style={{
                  margin: "0",
                  color: "#00b7ff",
                  fontWeight: "500",
                  fontSize: "1em",
                  minWidth: "max-content",
                }}
              >
                IKF Admin
                <div
                  style={{
                    background: "#00b7ff",
                    fontSize: "0.5em",
                    maxWidth: "min-content",
                    color: "#000",
                    padding: "5px",
                    borderRadius: "5px",
                    marginTop: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    signOut(auth);
                  }}
                >
                  Logout
                </div>
              </h1>
              <div style={{ flex: "1" }}></div>
            </>
          )}
          <NavLink
            exact
            to=""
            className={"header_navlink"}
            activeClassName="active"
            style={{
              width: getWindowDimensions().width > 600 ? "180px" : "100%",
              height: "100%",
              display: "flex",
              flexDirection:
                getWindowDimensions().width > 600 ? "row" : "column",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "normal",
            }}
            activeStyle={{
              display: "flex",
              flexDirection:
                getWindowDimensions().width > 600 ? "row" : "column",
              alignItems: "center",
              justifyContent: "center",
              color: "#00b7ff",
            }}
          >
            <BiTimeFive
              style={{
                marginRight: getWindowDimensions().width > 600 ? "10px" : "0px",
                fontSize: "1em",
              }}
            />
            <font style={{ fontSize: "0.8em" }}>Events</font>
          </NavLink>
          <NavLink
            exact
            to="/singers"
            className={"header_navlink"}
            activeClassName="active"
            style={{
              width: getWindowDimensions().width > 600 ? "180px" : "100%",
              height: "100%",
              display: "flex",
              flexDirection:
                getWindowDimensions().width > 600 ? "row" : "column",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "normal",
            }}
            activeStyle={{
              display: "flex",
              flexDirection:
                getWindowDimensions().width > 600 ? "row" : "column",
              alignItems: "center",
              justifyContent: "center",
              color: "#00b7ff",
            }}
          >
            <FaUsers
              style={{
                marginRight: getWindowDimensions().width > 600 ? "10px" : "0px",
                fontSize: "1em",
              }}
            />
            <font style={{ fontSize: "0.8em" }}>Singers</font>
          </NavLink>
          {/* <NavLink
            exact
            to="/songs"
            className={"header_navlink"}
            activeClassName="active"
            style={{
              width: getWindowDimensions().width > 600 ? "180px" : "100%",
              height: "100%",
              display: "flex",
              flexDirection:
                getWindowDimensions().width > 600 ? "row" : "column",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "normal",
            }}
            activeStyle={{
              display: "flex",
              flexDirection:
                getWindowDimensions().width > 600 ? "row" : "column",
              alignItems: "center",
              justifyContent: "center",
              color: "#00b7ff",
            }}
          >
            <FaMusic
              style={{
                marginRight: getWindowDimensions().width > 600 ? "10px" : "0px",
                fontSize: "1em",
              }}
            />
            <font style={{ fontSize: "0.8em" }}>Songs</font>
          </NavLink> */}
          {auth.currentUser.uid === "Irwsg1x7AucPGB0u2UNf9ebMJGQ2" && (
            <NavLink
              exact
              to="/admins"
              className={"header_navlink"}
              activeClassName="active"
              style={{
                width: getWindowDimensions().width > 600 ? "180px" : "100%",
                height: "100%",
                display: "flex",
                flexDirection:
                  getWindowDimensions().width > 600 ? "row" : "column",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "normal",
              }}
              activeStyle={{
                display: "flex",
                flexDirection:
                  getWindowDimensions().width > 600 ? "row" : "column",
                alignItems: "center",
                justifyContent: "center",
                color: "#00b7ff",
              }}
            >
              <FaLock
                style={{
                  marginRight:
                    getWindowDimensions().width > 600 ? "10px" : "0px",
                  fontSize: "1em",
                }}
              />
              <font style={{ fontSize: "0.8em" }}>Super</font>
            </NavLink>
          )}
        </div>
      )}
    </>
  );
}

export default Header;
